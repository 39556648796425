import React, { Component } from "react";
import Navbar from "./Navbar";
import "./Services.css";
import daycarelogo from "./images/d17.png";
import boardinglogo from "./images/d44.png";
import bathinglogo from "./images/d46.png";
import Footer from "./Footer";

class Services extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="services">
        <Navbar />
        <div className="services-container">
          <div>
            <h4 className="services-heading">Services & Pricing</h4>
          </div>
          <div className="services-cols">
            <div className="services-col">
              <div className="services-item">
                <div className="services-content">
                  <div className="services-section">
                    <h4 className="services-header">
                      <i className="fas fa-hand-holding-heart"></i> Daycare
                    </h4>
                  </div>
                  <img src={daycarelogo} alt="dogs playing" />
                  <div className="services-text">
                    <h4>
                      <i className="fas fa-paw"></i> Monday – Friday{" "}
                    </h4>

                    <hr />

                    <p>Large Dog ( >25lb): $30/day</p>

                    <p>Small Dog (25lb or less): $25/day</p>

                    <h4>
                      <i className="fas fa-paw"></i> Discounted 10-day Daycare
                      Packages
                    </h4>
                    <hr />
                    <p>Large Dog - $270 ($27/day for 10 days)</p>
                    <p>Small Dog - $225 ($22.50/day for 10 days)</p>
                    <p>
                      <em>
                        Note: We do not feed daycare dogs unless owner requests
                        that we do so, they are provided unlimited access to
                        water at all times.
                      </em>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="services-col">
              <div className="services-item">
                <div className="services-content">
                  <div className="services-section">
                    <h4 className="services-header">
                      <i class="fas fa-home"></i> Boarding
                    </h4>
                  </div>

                  <img src={boardinglogo} alt="picture of dog playing" />

                  <div className="services-text">
                    <p>
                      <i className="fas fa-paw"></i> All dogs $46 per night.
                    </p>
                    <p>
                      <i className="fas fa-paw"></i> Housemates boarding
                      together discount $37/night per dog after the first dog.
                    </p>
                    <p>
                      <i className="fas fa-paw"></i> Special holidays may
                      require a $50 non-refundable deposit.
                    </p>
                    <p>
                      <i className="fas fa-paw"></i> $20 fee for picking up
                      after 12pm Monday – Saturday (no fee for dropping off
                      after 12pm).{" "}
                    </p>
                    <p>
                      <i className="fas fa-paw"></i> $20 fee for picking up
                      /dropping off Sunday morning before 12pm. (no fee for
                      picking up/dropping off after 12pm on Sundays).
                    </p>
                    <p>
                      <i className="fas fa-paw"></i> Long Term boarding
                      available.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="services-col">
              <div className="services-item">
                <div className="services-content">
                  <div className="services-section">
                    <h4 className="services-header">
                      <i class="fas fa-bath"></i> Bathing Services
                    </h4>
                  </div>

                  <img src={bathinglogo} alt="dirty dog" />

                  <div className="services-text">
                    <p>
                      <i className="fas fa-paw"></i> Exit Baths available upon
                      request.
                    </p>

                    <p>
                      <em>
                        Note: Please notify staff of pick up date and time to
                        ensure your dog goes home clean. Our baths are quick
                        exit baths, <b>NOT</b> grooming certified.
                      </em>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="services-payment">
            <div className="services-payment-container">
              <div className="services-cols">
                <div className="services-col--payment">
                  <div className="services-item">
                    <div className="services-section">
                      <h4 className="services-header">
                        Additional Information + Payment
                      </h4>
                    </div>
                    <div className="services-content">
                      <p>
                        <i className="fas fa-paw"></i> Food, toys, treats,
                        bedding, playtime and 20 minute trail walk are all
                        provided by us and are included in boarding and daycare
                        prices. Feel free to bring any other items for your dog
                        that you want them to have during their stay.
                      </p>
                      <p>
                        <i className="fas fa-paw"></i> If your dog has any
                        special needs, conditions or anything you feel we should
                        know about, please detail this in the enrollment form
                        and notify staff at drop off.{" "}
                      </p>
                      <p>
                        <i className="fas fa-paw"></i> Please fill out
                        medication form if your dog is on any current
                        medications that you would like us to administer during
                        their stay and notify staff at drop off.
                      </p>
                      <p>
                        <i className="fas fa-paw"></i> Go to the enrollment form
                        tab to request boarding/daycare dates and submit the
                        form online or print out and bring with you upon drop
                        off. We will also need copies of your dog(s) up to date
                        vaccination records.
                      </p>
                      <p>
                        <i className="fas fa-paw"></i> Tours of our facility are
                        available by appointment - please call or email.
                      </p>
                      <h4 className="services-header">Payment Options</h4>
                      <hr />
                      <p>
                        <em>We accept cash, check, or PayPal</em>
                      </p>
                      <p>Click icon for payment portal access</p>
                      <p>Send money to reserve@crackerdogcare.com</p>
                      <a
                        className="services-paypal"
                        href="https://www.paypal.com/us/home"
                        target="_blank"
                      >
                        <i className="fab fa-cc-paypal"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Services;
