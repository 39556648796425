import React, { Component } from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Landing from "./components/layout/Landing";
import About from "./components/layout/About";
import Services from "./components/layout/Services";
import Emergency from "./components/layout/Emergency";
import Apply from "./components/layout/Apply";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";
import Forms from "../../client/src/components/layout/Forms";
import medForm from "../../client/src/components/layout/MedForm";
import CreateNotes from "./components/layout/CreateNotes";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false
    };
  }
  render() {
    return (
        <Router>
          <div className="App">
            <Route exact path="/" component={Landing} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/about" component={About} />
            <Route exact path="/emergency" component={Emergency} />
            <Route exact path="/apply" component={Apply} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/forms" component={Forms} />
            <Route exact path="/medform" component={medForm} />
            <Route exact path="/createposts" component={CreateNotes} />
            <Switch>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
            </Switch>
          </div>
        </Router>
    );
  }
}
export default App;
