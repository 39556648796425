import React, { Component } from "react";
import { Link } from "react-router-dom";
import Reveal from "react-reveal/Reveal";
import "./Navbar.css";
import $ from "jquery";
import logo from "./images/crackerdog-logo.png";




class Navbar extends Component {
  componentDidMount() {
    $(".open").click(function(){
      $(".nav-slide").addClass("open");
      });
  
    $(".close").click(function(){
      $(".nav-slide").removeClass("open");
      });
  }
  render() {
    return (
      <div className="nav-fixed">
        <div className="nav-container">
          <div className="nav-cols">
            <div className="nav-col">
              <span className="open">&#9776;</span>  
            </div>
            <div className="nav-col">
              <Link to="/"><img src={logo} alt="crackerdog-logo" className="nav-logo"/></Link>
            </div>
            <div className="nav-col">
            
            </div>
          </div>
          <div className="nav-slide">
            <div className="nav-links">
              <div className="nav-content">
              <a className="close">&times;</a>
              <Reveal duration={4000}>
                <Link className="nav-link" to="/">Home</Link>
               </Reveal>
               <Reveal duration={4000}>
                <Link className="nav-link" to="/services">Services & Pricing</Link>
                </Reveal>
                <Reveal duration={4000}>
                <Link className="nav-link" to="/forms">Enrollment Form</Link>
                </Reveal>
                <Reveal duration={4000}>
                <Link className="nav-link" to="/medform">Medication Form</Link>
                </Reveal>
                <Reveal duration={4000}>
                <Link className="nav-link" to="/about">About Us</Link>
                </Reveal>
                <Reveal duration={4000}>
                <Link className="nav-link" to="/emergency">Emergency</Link>
                </Reveal>
                <Reveal duration={4000}>
                <Link className="nav-link" to="/apply">Apply Now!</Link>
                </Reveal>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
