import React, { Component } from "react";
import dog1 from "./images/cd-closure.png";
import "./Landing.css";


class Landing extends Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
    return (
      // ********* Start of landing-page ********* //
   
      <div className="landing-page">
        <div className="landing-page--container">
        <div className="landing-gallery">
          <div className="landing-gallery--container">
          <div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active" data-interval="">
                    <img src={dog1} className="d-block w-100" alt="..."/>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      // ********* End of landing-page ********* //
    );
  }
}

export default Landing;
