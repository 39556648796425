import React, { Component } from "react";
import { Form, Input } from "reactstrap";
import axios from "axios";
import Footer from "../layout/Footer";
import "./Forms.css";
import Navbar from "./Navbar";

class Forms extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      date: "",
      lastname: "",
      address: "",
      location: "",
      cellphone: "",
      homephone: "",
      workphone: "",
      dognumber: "",
      dateoptional: "",
      nameoptional: "",
      lastnameoptional: "",
      addressoptional: "",
      locationoptional: "",
      cellphoneoptional: "",
      homephoneoptional: "",
      workphoneoptional: "",
      emailoptional: "",
      contactphonealt: "",
      contactphone: "",
      emergencycontact: "",
      emergencycontact2: "",
      crackerdoginfo: "",
      dogname: "",
      doglastname: "",
      breed: "",
      descriptioncolor: "",
      weight: "",
      sex: "",
      sn: "",
      dob: "",
      vetname: "",
      vetphone: "",
      reservation: "",
      boardingreq: "",
      boardingduration: "",
      boardingEnd: "",
      daycarereq: "",
      daycareduration: "",
      daycareEnd: "",
      medcondition: "",
      allergies: "",
      dogmed: "",
      dogfoodpreference: "",
      dogfoodam: "",
      dogfoodnoon: "",
      dogfoodnight: "",
      diet: "",
      dietquestion: "",
      breederbtn: "",
      shelterfound: "",
      friendfound: "",
      selffound: "",
      other: "",
      dogq: "",
      companion: "",
      hunting: "",
      protection: "",
      kids: "",
      otherTwo: "",
      training: "",
      fenceq1: "",
      fenceq2: "",
      fenceq3: "",
      fenceq4: "",
      fenceq5: "",
      fenceq6: "",
      dogtime: "",
      dogtime2: "",
      dogsleep: "",
      dogsleep2: "",
      dogsleep3: "",
      dogsleep4: "",
      dogsleep5: "",
      otheralt: "",
      doglostq: "",
      dogbiteq: "",
      attackq: "",
      attackq2: "",
      attackq3: "",
      attackq4: "",
      attackq5: "",
      otheraltthree: "",
      dogbitehuman: "",
      dayCare1: "",
      dayCare2: "",
      dayCare3: "",
      dayCare4: "",
      dayCare5: "",
      otherAltCare: "",
      dogAct: "",
      dogAct2: "",
      dogAct3: "",
      dogAct4: "",
      dogAct5: "",
      otherAltAct: "",
      playType: "",
      playType2: "",
      playType3: "",
      playType4: "",
      playType5: "",
      otherAltPlay: "",
      dogPark1: "",
      dogPark2: "",
      dogPark3: "",
      dogPark4: "",
      otherAltFour: "",
      dogcommand1: "",
      dogcommand2: "",
      dogcommand3: "",
      dogcommand4: "",
      dogcommand5: "",
      otherAltSix: "",
      dogToys: "",
      dogProtectYes: "",
      dogProtectNo: "",
      dogToysYes: "",
      dogToysNo: "",
      dogAgressionYes: "",
      dogAgressionNo: "",
      dogAgression: "",
      dogLeashYes: "",
      dogLeashNo: "",
      dogChewYes: "",
      dogChewNo: "",
      dogChew: "",
      dogpetBelly: "",
      dogpetHead: "",
      dogpetEar: "",
      dogpetRump: "",
      dogpetBack: "",
      dogpetIdk: "",
      dogFaveArea: "",
      dogPlayFave: "",
      dogPlayFave2: "",
      dogPlayFave3: "",
      dogPlayFave4: "",
      dogPlay: "",
      dogSensitive: "",
      dogSensitive2: "",
      sensitiveDog: "",
      houseDog: "",
      houseDog2: "",
      houseDog3: "",
      listenDog: "",
      listenDog2: "",
      listenDog3: "",
      listenDog4: "",
      swimDog: "",
      swimDog2: "",
      swimDog3: "",
      swimDog4: "",
      groomDog: "",
      groomDog2: "",
      groomDog3: "",
      groomDog4: "",
      groomDog5: "",
      protectiveDog: "",
      protectiveDog2: "",
      protectiveDog3: "",
      protectiveDog4: "",
      protectiveDog5: "",
      protectiveDog6: "",
      treatsDog: "",
      treatsDog2: "",
      treatsDog3: "",
      treatsDog4: "",
      treatsDog5: "",
      afraidDog: "",
      afraidDog2: "",
      afraidDog3: "",
      afraidDog4: "",
      afraidDogText: "",
      dogGear: "",
      dogGear2: "",
      dogGearText: "",
      negativeDog: "",
      negativeDog2: "",
      negativeDog3: "",
      negativeDog4: "",
      negativeDog5: "",
      negativeDog6: "",
      negativeDog7: "",
      negativeDog8: "",
      negativeDog9: "",
      negativeDog10: "",
      dogAdditionalInfo: "",
      dogRoutine: "",
      authorization: "",
      signature: "",
      dateAuthorization: "",
      contract1: "",
      contract2: "",
      contract3: "",
      contract4: "",
      vaccine: "",
      collar: "",
      emergencyAuth: "",
      pickupAuth: "",
      dropoffAuth: "",
      contractAuthorization: "",
      ownersignature: "",
      dateAuthorizationTwo: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  submitConfirmation() {
    window.scrollTo(0, 0);
    document.querySelector(".alert").style.display = "block";
    setTimeout(function () {
      document.querySelector(".alert").style.display = "none";
    }, 3000);
    document.getElementById("enrollmentform").reset();
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  async handleSubmit(e) {
    e.preventDefault();

    const {
      name,
      email,
      date,
      lastname,
      address,
      location,
      cellphone,
      homephone,
      workphone,
      dognumber,
      dateoptional,
      nameoptional,
      lastnameoptional,
      addressoptional,
      locationoptional,
      cellphoneoptional,
      homephoneoptional,
      workphoneoptional,
      emailoptional,
      contactphone,
      emergencycontact,
      emergencycontact2,
      contactphonealt,
      crackerdoginfo,
      dogname,
      doglastname,
      breed,
      descriptioncolor,
      weight,
      sex,
      sn,
      dob,
      vetname,
      vetphone,
      reservation,
      boardingreq,
      boardingduration,
      boardingEnd,
      daycarereq,
      daycareduration,
      daycareEnd,
      medcondition,
      allergies,
      dogmed,
      dogfoodpreference,
      dogfoodam,
      dogfoodnoon,
      dogfoodnight,
      diet,
      dietquestion,
      breederbtn,
      shelterfound,
      friendfound,
      selffound,
      other,
      dogq,
      companion,
      hunting,
      protection,
      kids,
      otherTwo,
      training,
      fenceq1,
      fenceq2,
      fenceq3,
      fenceq4,
      fenceq5,
      fenceq6,
      dogtime,
      dogtime2,
      dogsleep,
      dogsleep2,
      dogsleep3,
      dogsleep4,
      dogsleep5,
      otheralt,
      doglostq,
      dogbiteq,
      attackq,
      attackq2,
      attackq3,
      attackq4,
      attackq5,
      otheraltthree,
      dogbitehuman,
      dayCare1,
      dayCare2,
      dayCare3,
      dayCare4,
      dayCare5,
      otherAltCare,
      dogAct,
      dogAct2,
      dogAct3,
      dogAct4,
      dogAct5,
      otherAltAct,
      playType,
      playType2,
      playType3,
      playType4,
      playType5,
      otherAltPlay,
      dogPark1,
      dogPark2,
      dogPark3,
      dogPark4,
      otherAltFour,
      dogcommand1,
      dogcommand2,
      dogcommand3,
      dogcommand4,
      dogcommand5,
      otherAltSix,
      dogToys,
      dogProtectYes,
      dogProtectNo,
      dogToysYes,
      dogToysNo,
      dogAgressionYes,
      dogAgressionNo,
      dogAgression,
      dogLeashYes,
      dogLeashNo,
      dogChewYes,
      dogChewNo,
      dogChew,
      dogpetBelly,
      dogpetHead,
      dogpetEar,
      dogpetRump,
      dogpetBack,
      dogpetIdk,
      dogFaveArea,
      dogPlayFave,
      dogPlayFave2,
      dogPlayFave3,
      dogPlayFave4,
      dogPlay,
      dogSensitive,
      dogSensitive2,
      sensitiveDog,
      houseDog,
      houseDog2,
      houseDog3,
      listenDog,
      listenDog2,
      listenDog3,
      listenDog4,
      swimDog,
      swimDog2,
      swimDog3,
      swimDog4,
      groomDog,
      groomDog2,
      groomDog3,
      groomDog4,
      groomDog5,
      protectiveDog,
      protectiveDog2,
      protectiveDog3,
      protectiveDog4,
      protectiveDog5,
      protectiveDog6,
      treatsDog,
      treatsDog2,
      treatsDog3,
      treatsDog4,
      treatsDog5,
      afraidDog,
      afraidDog2,
      afraidDog3,
      afraidDog4,
      afraidDogText,
      dogGear,
      dogGear2,
      dogGearText,
      negativeDog,
      negativeDog2,
      negativeDog3,
      negativeDog4,
      negativeDog5,
      negativeDog6,
      negativeDog7,
      negativeDog8,
      negativeDog9,
      negativeDog10,
      dogAdditionalInfo,
      dogRoutine,
      authorization,
      signature,
      dateAuthorization,
      contract1,
      contract2,
      contract3,
      contract4,
      vaccine,
      collar,
      emergencyAuth,
      pickupAuth,
      dropoffAuth,
      contractAuthorization,
      ownersignature,
      dateAuthorizationTwo,
    } = this.state;

    // eslint-disable-next-line
    const form = axios.post("/form", {
      name,
      email,
      date,
      lastname,
      address,
      location,
      cellphone,
      homephone,
      workphone,
      dognumber,
      dateoptional,
      nameoptional,
      lastnameoptional,
      addressoptional,
      locationoptional,
      cellphoneoptional,
      homephoneoptional,
      workphoneoptional,
      emailoptional,
      emergencycontact,
      emergencycontact2,
      contactphone,
      contactphonealt,
      crackerdoginfo,
      dogname,
      doglastname,
      breed,
      descriptioncolor,
      weight,
      sex,
      sn,
      dob,
      vetname,
      vetphone,
      reservation,
      boardingreq,
      boardingduration,
      boardingEnd,
      daycarereq,
      daycareduration,
      daycareEnd,
      medcondition,
      allergies,
      dogmed,
      dogfoodpreference,
      dogfoodam,
      dogfoodnoon,
      dogfoodnight,
      diet,
      dietquestion,
      breederbtn,
      shelterfound,
      friendfound,
      selffound,
      other,
      dogq,
      companion,
      hunting,
      protection,
      kids,
      otherTwo,
      training,
      fenceq1,
      fenceq2,
      fenceq3,
      fenceq4,
      fenceq5,
      fenceq6,
      dogtime,
      dogtime2,
      dogsleep,
      dogsleep2,
      dogsleep3,
      dogsleep4,
      dogsleep5,
      otheralt,
      doglostq,
      dogbiteq,
      attackq,
      attackq2,
      attackq3,
      attackq4,
      attackq5,
      otheraltthree,
      dogbitehuman,
      dayCare1,
      dayCare2,
      dayCare3,
      dayCare4,
      dayCare5,
      otherAltCare,
      dogAct,
      dogAct2,
      dogAct3,
      dogAct4,
      dogAct5,
      otherAltAct,
      playType,
      playType2,
      playType3,
      playType4,
      playType5,
      otherAltPlay,
      dogPark1,
      dogPark2,
      dogPark3,
      dogPark4,
      otherAltFour,
      dogcommand1,
      dogcommand2,
      dogcommand3,
      dogcommand4,
      dogcommand5,
      otherAltSix,
      dogToys,
      dogProtectYes,
      dogProtectNo,
      dogToysYes,
      dogToysNo,
      dogAgressionYes,
      dogAgressionNo,
      dogAgression,
      dogLeashYes,
      dogLeashNo,
      dogChewYes,
      dogChewNo,
      dogChew,
      dogpetBelly,
      dogpetHead,
      dogpetEar,
      dogpetRump,
      dogpetBack,
      dogpetIdk,
      dogFaveArea,
      dogPlayFave,
      dogPlayFave2,
      dogPlayFave3,
      dogPlayFave4,
      dogPlay,
      dogSensitive,
      dogSensitive2,
      sensitiveDog,
      houseDog,
      houseDog2,
      houseDog3,
      listenDog,
      listenDog2,
      listenDog3,
      listenDog4,
      swimDog,
      swimDog2,
      swimDog3,
      swimDog4,
      groomDog,
      groomDog2,
      groomDog3,
      groomDog4,
      groomDog5,
      protectiveDog,
      protectiveDog2,
      protectiveDog3,
      protectiveDog4,
      protectiveDog5,
      protectiveDog6,
      treatsDog,
      treatsDog2,
      treatsDog3,
      treatsDog4,
      treatsDog5,
      afraidDog,
      afraidDog2,
      afraidDog3,
      afraidDog4,
      afraidDogText,
      dogGear,
      dogGear2,
      dogGearText,
      negativeDog,
      negativeDog2,
      negativeDog3,
      negativeDog4,
      negativeDog5,
      negativeDog6,
      negativeDog7,
      negativeDog8,
      negativeDog9,
      negativeDog10,
      dogAdditionalInfo,
      dogRoutine,
      authorization,
      signature,
      dateAuthorization,
      contract1,
      contract2,
      contract3,
      contract4,
      vaccine,
      collar,
      emergencyAuth,
      pickupAuth,
      dropoffAuth,
      contractAuthorization,
      ownersignature,
      dateAuthorizationTwo,
    });

    this.submitConfirmation();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="enrollment-form">
        <Navbar />
        <div className="enrollment-container">
          <div className="enrollment-heading">
            <h4 className="enrollment-header">Enrollment Form</h4>
            <center>
              <div className="alert">
                <p> 🐶 Form Submission Successful! 🎉</p>
              </div>
            </center>
            <p className=" ">(Fill out one form per dog)</p>
            <div className="enrollment-pdf-download">
              <div className="enrollment-info">
                <span>
                  Don't want to fill out the eForm? Click icon below to print
                  form!
                </span>
              </div>
              <p className="">
                <span>Download Enrollment Form docx.</span>
              </p>
              <a
                href="https://drive.google.com/file/d/1DQr1rFYHkxQhtX-a1Tv7rjVCsayJ-lsD/view?usp=sharing"
                className="contact-PDF"
                alt="forms"
              >
                <i className="fas fa-arrow-circle-down"></i>
              </a>
            </div>
          </div>

          <div className="enroll-form--container">
            <Form
              id="enrollmentform"
              onSubmit={this.handleSubmit}
              method="POST"
              action="form"
            >
              <h4>Owner Information</h4>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="enrollment-label">
                    <i className="fas fa-calendar-alt"></i> Today's Date:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input type="date" name="date" onChange={this.handleChange} />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="enrollment-label">
                    <i className="fas fa-signature"></i> First Name:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input type="text" name="name" onChange={this.handleChange} />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="enrollment-label">
                    <i className="fas fa-signature"></i> Last Name:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="lastname"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="enrollment-label">
                    <i className="far fa-address-card"></i> Address:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="address"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="enrollment-label">
                    <i className="fas fa-city"></i> City, State and Zip:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="location"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    <i class="fas fa-mobile"></i> Cell Phone:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="cellphone"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="enrollment-label">
                    <i className="fas fa-phone"></i> Home Phone:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="homephone"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="enrollment-label">
                    <i className="fas fa-tty"></i> Work Phone:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="workphone"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="enrollment-label">
                    <i className="fas fa-at"></i> Email:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="email"
                    name="email"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="enrollment-label">
                    <i className="fas fa-home"></i> Number of dogs in household:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="number"
                    name="dognumber"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <p className="enrollment-label">
                Owner #2 Information (Optional)
              </p>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="enrollment-label">
                    <i className="fas fa-calendar-alt"></i> Today's Date:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="date"
                    name="dateoptional"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="enrollment-label">
                    <i className="fas fa-signature"></i> First Name:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="nameoptional"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="enrollment-label">
                    <i className="fas fa-signature"></i> Last Name:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="lastnameoptional"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="enrollment-label">
                    <i className="far fa-address-card"></i> Address:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="addressoptional"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="enrollment-label">
                    <i className="fas fa-city"></i> City, State and Zip:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="locationoptional"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    <i class="fas fa-mobile"></i> Cell Phone:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="cellphoneoptional"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="enrollment-label">
                    <i className="fas fa-phone"></i> Home Phone:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="homephoneoptional"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="enrollment-label">
                    <i className="fas fa-tty"></i> Work Phone:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="workphoneoptional"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="enrollment-label">
                    <i className="fas fa-at"></i> Email:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="email"
                    name="emailoptional"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <h4 className="enrollment">Emergency Contact Information</h4>
              <p>(other than the above information and the vet information)</p>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    <i className="fas fa-signature"></i> Contact Name:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="emergencycontact"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">Contact Phone:</label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="contactphone"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <p className="">Emergency Contact #2 (optional)</p>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    <i className="fas fa-signature"></i> Contact Name:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="emergencycontact2"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">Contact Phone:</label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="contactphonealt"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    <i class="fas fa-user-friends"></i> How did you hear about
                    Crackerdog?
                  </label>
                  <p className="enrollment-disclaimer">
                    (Ex: Facebook, Instagram, Google, through the grapevine,
                    etc.)
                  </p>
                </div>
                <div className="enrollment-col">
                  <Input
                    className="enrollment-textarea"
                    type="textarea"
                    name="crackerdoginfo"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <h4 className="mt-3">Dog Information</h4>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">First Name:</label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="dogname"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">Last Name:</label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="doglastname"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">Breed (if mixed, please guess):</label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="breed"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    <i class="fas fa-pencil-alt"></i> Color/Description:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="textarea"
                    name="descriptioncolor"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    <i class="fas fa-weight"></i> Weight(lbs):
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="weight"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">Sex:</label>
                </div>
                <div className="enrollment-col">
                  <Input type="text" name="sex" onChange={this.handleChange} />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">Spayed or Neutered (Yes or No):</label>
                </div>
                <div className="enrollment-col">
                  <Input type="text" name="sn" onChange={this.handleChange} />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    <i class="fas fa-birthday-cake"></i> DOB or approx age:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input type="date" name="dob" onChange={this.handleChange} />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    <i class="fas fa-hand-holding-medical"></i> Vet Clinic Name:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="vetname"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    <i class="fas fa-phone-alt"></i> Vet Phone:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    name="vetphone"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Boarding, Daycare, or Both reservation?
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="textarea"
                    name="reservation"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    {" "}
                    <i class="fas fa-calendar-check"></i> Requested Boarding
                    Dates:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    className="enrollment-date"
                    type="date"
                    name="boardingreq"
                    onChange={this.handleChange}
                  />
                  <p className="enrollment-dates">to</p>
                  <Input
                    className="enrollment-date"
                    type="date"
                    name="boardingduration"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    <i class="fas fa-calendar-check"></i> Requested Daycare
                    Dates:
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    className="enrollment-date"
                    type="date"
                    name="daycarereq"
                    onChange={this.handleChange}
                  />
                  <p className="enrollment-dates">to</p>
                  <Input
                    className="enrollment-date"
                    type="date"
                    name="daycareEnd"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <p className="enrolllment-questionnaire">
                * Please take the time to thoroughly fill out the questionnaire.
                It is lengthy, but will help us make your dog's stay more
                comfortable. Thank you! *
              </p>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    <i class="fas fa-hand-holding-medical"></i> Does your dog
                    have any medical conditions? If yes, please explain.
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="textarea"
                    name="medcondition"
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    <i class="fas fa-allergies"></i> Does your dog have
                    allergies? If yes, what is he/she allergic to?
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="textarea"
                    name="allergies"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    <i class="fas fa-pills"></i> Does your dog take any
                    medications? If yes, please go to the medication form tab
                    and submit form online or print out and bring with you upon
                    drop off.
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="textarea"
                    name="dogmed"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    What kind of food do you feed your dog?
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="textarea"
                    name="dogfoodpreference"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className=" ">
                    <i class="fas fa-utensils"></i> How many cups? (8 oz.)
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    className="enrollment-date"
                    placeholder="☀️ AM"
                    type="text"
                    name="dogfoodam"
                    onChange={this.handleChange}
                  />
                  <Input
                    className="enrollment-date"
                    placeholder="🌤 Afternoon"
                    type="text"
                    name="dogfoodnoon"
                    onChange={this.handleChange}
                  />
                  <Input
                    className="enrollment-date"
                    placeholder="🌙 PM"
                    type="text"
                    name="dogfoodnight"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <p className="enrolllment-questionnaire">
                * We provide dog food at our facility: Canidae Chicken + Rice
                Formula (dry or wet food) *
              </p>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Does your dog have any dietary restrictions? Yes or No.
                  </label>
                  <p className="enrolllment-questionnaire">
                    If yes, please explain.
                  </p>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="textarea"
                    name="diet"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Is your dog allowed to have treats? Yes or No.
                  </label>
                  <p className="enrolllment-questionnaire">
                    If yes, please explain which type
                  </p>
                </div>
                <div className="enrollment-col">
                  <Input
                    className="mb-3"
                    type="textarea"
                    name="dietquestion"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">Where or how did you get your dog?</label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        <i class="fas fa-paw"></i> Breeder
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="breederbtn"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        <i class="fas fa-warehouse"></i> Shelter/SPCA
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="shelterfound"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        <i class="fas fa-user-friends"></i> Friend
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="friendfound"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        <i class="fas fa-search"></i> Found him/her
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="selffound"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>
                  <div>
                    <Input
                      type="text"
                      placeholder="Other:"
                      className="enroll-check "
                      name="other"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">How long have you had your dog?</label>
                </div>
                <div className="enrollment-col">
                  <Input
                    className=""
                    type="textarea"
                    name="dogq"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">Why did you get your dog?</label>
                </div>

                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        <i class="far fa-heart"></i> Companion
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="companion"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        <i class="fas fa-feather-alt"></i> Hunting
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="hunting"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        <i class="fas fa-lock"></i> Protection
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="protection"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        <i class="fas fa-child"></i> Kids
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="kids"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>

                  <Input
                    type="text"
                    placeholder="Other:"
                    className="enroll-check "
                    name="otherTwo"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Has your dog ever been to a training class? Yes or No. If
                    yes, where?
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="textarea"
                    name="training"
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label>
                    Has your dog ever gotten out of a fenced area (check all
                    that apply)?
                  </label>
                </div>

                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">No</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="fenceq1"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">Dug out</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="fenceq2"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Jumped a fence
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="fenceq3"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Found a hole
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="fenceq4"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">Climbed</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="fenceq5"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Where does your dog spend most of his time?
                  </label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">Outdoors</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogtime"
                        value="✔️"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">Indoors</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogtime2"
                        value="✔️"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">Where does your dog sleep?</label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">Dog bed</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogsleep"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">My bed</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogsleep2"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">Crate</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogsleep3"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">Floor</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogsleep4"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">Couch</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogsleep5"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>
                  <Input
                    type="text"
                    placeholder="Other:"
                    className="enroll-check"
                    name="otheralt"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Have you ever lost your dog? Yes or No.
                  </label>
                  <p className="enrolllment-questionnaire">
                    If yes, please explain how it happened?
                  </p>
                </div>
                <div className="enrollment-col">
                  <Input
                    className=""
                    type="textarea"
                    name="doglostq"
                    className="enroll-check"
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Has your dog ever bitten another dog? Yes or No.
                  </label>
                  <p className="enrolllment-questionnaire">
                    If yes, please describe what happened.
                  </p>
                </div>
                <div className="enrollment-col">
                  <Input
                    className=""
                    type="textarea"
                    name="dogbiteq"
                    className="enroll-check"
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Has your dog ever been attacked by another dog? (check all
                    that apply)
                  </label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">No</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="attackq"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Yes, but I wasn't there
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="attackq2"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Yes, my dog responded by whimpering
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="attackq3"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Yes, my dog responded by fighting
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="attackq4"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Yes, my dog was punctured (bitten) by the other dog{" "}
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="attackq5"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>
                  <div>
                    <Input
                      type="textarea"
                      placeholder="Other:"
                      className=" enroll-check "
                      name="otheraltthree"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Has your dog ever bitten a human? Yes or No.
                  </label>
                  <p className="enrolllment-questionnaire">
                    If yes, please describe what happened.
                  </p>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="textarea"
                    className=""
                    name="dogbitehuman"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    If your dog has ever attended a Daycare or Boarding
                    Facility, please check the answer(s) that best fit your
                    dog's experience?
                  </label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Never been to Daycare or Boarded
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dayCare1"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        My dog showed no signs of stress while there or when I
                        dropped him off
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dayCare2"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        My dog did NOT get to play with other dogs
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dayCare3"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        My dog did get to play with other dogs
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dayCare4"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Don't know (didn't get any feedback from the facility)
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dayCare5"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>
                  <div>
                    <Input
                      type="textarea"
                      placeholder="Other:"
                      className=" enroll-check "
                      name="otherAltCare"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    How does your dog act on a leash around other dogs (choose
                    all that apply)?
                  </label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Doesn't pay any attention to them
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogAct"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Lunges at them
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogAct2"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Barks at them
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogAct3"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Growls at them
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogAct4"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Hides behind me
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogAct5"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>
                  <div>
                    <Input
                      type="textarea"
                      placeholder="Other:"
                      className="enroll-check "
                      name="otherAltAct"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    How does your dog play with other dogs?
                  </label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        He doesn't play with other dogs
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="playType"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Lunges at them
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="playType2"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Barks at them
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="playType3"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Growls at them
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="playType4"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Hides behind me
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="playType5"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>
                  <div>
                    <Input
                      type="textarea"
                      placeholder="Other:"
                      className="enroll-check "
                      name="otherAltPlay"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    How does your dog play at the dog park?
                  </label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        We don't go to dog parks
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogPark1"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Plays with the other dogs wrestling and chasing
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogPark2"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Stays by me
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogPark3"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="enroll-checkbox-text">
                        Walks around sniffing other dogs not really playing{" "}
                      </label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogPark4"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>
                  <div className="">
                    <Input
                      type="textarea"
                      placeholder="Other:"
                      className=""
                      name="otherAltFour"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    What command do you use to get your dog to do the following?
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    id="command-Input"
                    className="enroll-check"
                    placeholder="Come when called:"
                    name="dogcommand1"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <Input
                    type="text"
                    id="command-Input"
                    placeholder="Sit:"
                    className="enroll-check"
                    name="dogcommand2"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="enrollment-col">
                  <Input
                    type="text"
                    id="command-Input"
                    placeholder="Down:"
                    className="enroll-check"
                    name="dogcommand3"
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <Input
                    type="text"
                    id="command-Input"
                    placeholder="Stop doing something:"
                    className="enroll-check"
                    name="dogcommand4"
                    onChange={this.handleChange}
                  />
                </div>
                <div className="enrollment-col">
                  <Input
                    type="textarea"
                    placeholder="Other:"
                    className=" enroll-check "
                    name="otherAltSix"
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Please list your dog's favorite toys
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="textarea"
                    className=""
                    name="dogToys"
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Is he/she protective of toys around people/dogs?
                  </label>
                </div>

                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">Yes</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogProtectYes"
                        value="✔️"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="checkbox-col">
                      <label className="">No</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogProtectNo"
                        value="✔️"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Has your dog ever shared his/her toys with other animals?
                  </label>
                </div>

                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">Yes</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogToysYes"
                        value="✔️"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="checkbox-col">
                      <label className="">No</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogToysNo"
                        value="✔️"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Has your dog ever shown any aggression towards humans? Yes
                    or No. If yes, please describe the situation.
                  </label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">Yes</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogAgressionYes"
                        value="✔️"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="checkbox-col">
                      <label className="">No</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogAgressionNo"
                        value="✔️"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <Input
                    type="textarea"
                    className=""
                    name="dogAgression"
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Do you ever take your dog off-leash in an unfenced area? Yes
                    or No. (don’t worry, we will never ever do this)
                  </label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">Yes</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogLeashYes"
                        value="✔️"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="checkbox-col">
                      <label className="">No</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogLeashNo"
                        value="✔️"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Does your dog destructively chew? Yes or No. If yes, what
                    items does he/she chew?
                  </label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">Yes</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogChewYes"
                        value="✔️"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="checkbox-col">
                      <label className="">No</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogChewNo"
                        value="✔️"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div>
                    <label className="enrollment-questionnaire">
                      If yes, explain please
                    </label>
                    <Input
                      type="textarea"
                      className=""
                      name="dogChew"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    What is your dog's favorite place to be pet (check all that
                    apply)?
                  </label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">Belly</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogpetBelly"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Head</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogpetHead"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Ears</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogpetEar"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Rump</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogpetRump"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Back</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogpetBack"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Don't know</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogpetIdk"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>
                  <div>
                    <Input
                      type="textarea"
                      placeholder="Other:"
                      className=" enroll-check mb-3"
                      name="dogFaveArea"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    What games do you play with your dog (check all that apply)?
                  </label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">Tug-O-War</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogPlayFave"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Wrestling</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogPlayFave2"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Keep Away</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogPlayFave3"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Retrieving</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogPlayFave4"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>
                  <div>
                    <Input
                      type="textarea"
                      placeholder="Other:"
                      className=" enroll-check"
                      name="dogPlay"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Is your dog sensitive to touch in certain areas of its body?
                    Yes or No If yes, please explain.
                  </label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">Yes</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogSensitive"
                        value="✔️"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="checkbox-col">
                      <label className="">No</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="sensitiveDog"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">Is your dog house trained?</label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">Yes</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="houseDog"
                        value="✔️"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="checkbox-col">
                      <label className="">No</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="houseDog2"
                        value="✔️"
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className="checkbox-col">
                      <label className="">Sort of</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="houseDog3"
                        value="✔️"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">Does your dog come when called?</label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">All of the time</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="listenDog"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Most of the time</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="listenDog2"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Some of the Time</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="listenDog3"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Never</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="listenDog4"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Choose the statement that best describes your dog's swimming
                    ability.
                  </label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">Strong Swimmer</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="swimDog"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Likes to splash around</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="swimDog2"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Can’t Swim</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="swimDog3"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Never Been</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="swimDog4"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    How does your dog behave for grooming (bath, brushing and
                    nails)?
                  </label>
                </div>

                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">Great</label>
                      <Input
                        type="checkbox"
                        name="groomDog"
                        className="enroll-check"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Good</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="groomDog2"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Okay</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="groomDog3"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Bad</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="groomDog4"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Very bad</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="groomDog5"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Is your dog protective of any of the following around other
                    dogs? (check all that apply)
                  </label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className=" ">Toys</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="protectiveDog"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Water</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="protectiveDog2"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">People</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="protectiveDog3"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Treats</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="protectiveDog4"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">None</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="protectiveDog5"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Don't know</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="protectiveDog6"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Can your dog have any goodies listed? (check all that apply)
                  </label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">Squeaky Toys</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="treatsDog"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Dog Bones</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="treatsDog2"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Greenies</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="treatsDog3"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Stuffed Animals</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="treatsDog4"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Tennis Balls</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="treatsDog5"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Is your dog afraid of (choose all those that apply):
                  </label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">Thunderstorms</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="afraidDog"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Lightning</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="afraidDog2"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Fireworks</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="afraidDog3"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">Loud Noises</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="afraidDog4"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>
                  <div>
                    <Input
                      type="textarea"
                      placeholder="Other:"
                      className="enroll-check"
                      name="afraidDogText"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Does your dog wear special gear besides a leash and a flat
                    collar when you walk him/her? Yes or No. If yes, what type
                    of gear?
                  </label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">Yes</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogGear"
                        value="✔️"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="checkbox-col">
                      <label className=" ">No</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="dogGear2"
                        value="✔️"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div>
                    <Input
                      className=""
                      placeholder="Other:"
                      type="textarea"
                      name="dogGearText"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Has your dog ever reacted negatively to any of the
                    following?(check boxes if applicable)
                  </label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">Male Dogs</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="negativeDog"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="">Female Dogs</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="negativeDog2"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="">Unaltered Dogs</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="negativeDog3"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="">Men</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="negativeDog4"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>

                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">Women</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="negativeDog5"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="">Children</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="negativeDog6"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="">Small Dogs</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="negativeDog7"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="">Medium Dogs</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="negativeDog8"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">Large Dogs</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        name="negativeDog9"
                        value="✔️"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="">Hyper Dogs</label>
                      <Input
                        type="checkbox"
                        className="enroll-check"
                        value="✔️"
                        name="negativeDog10"
                        onChange={this.handleChange}
                      />{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div className="enrollment-cols">
              <div className="enrollment-col">
              <label className="">
                  Please list anything else we should know about your dog?
                </label>
              </div>
              <div className="enrollment-col">
              <Input
                  type="textarea"
                  name="dogAdditionalInfo"
                  onChange={this.handleChange}
                />
              </div>
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <label className="">
                    Please briefly describe your dog's daily routine.
                  </label>
                </div>
                <div className="enrollment-col">
                  <Input
                    type="textarea"
                    name="dogRoutine"
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="enrollment-cols">
                
                <div className="enrollment-col">
                <h4 className=" ">Enrollment Verification</h4>

                </div>
                <div className="enrollment-col">
               
                <div className="checkbox-cols">
                <label className="">
                  {" "}
                  I ,
                  <Input
                    type="text"
                    name="authorization"
                    onChange={this.handleChange}
                    required
                  />
                  certify that all of the above information is correct.
                </label>

                
                </div>

                <div className="checkbox-cols">
                <Input
                  type="text"
                  name="signature"
                  placeholder="Signature:"
                  onChange={this.handleChange}
                  required
                />
                
                </div>

                <div className="checkbox-cols">
                <label htmlFor="
                
                ">Date:</label>
                     <Input
                  type="date"
                  placeholder="Date:"
                  name="dateAuthorization"
                  onChange={this.handleChange}
                  required
                />
               
                </div>
                
              
                </div>
        
              
           
            
           
              </div>

              <div className="enrollment-cols">
                <div className="enrollment-col">
                  <h4 className=" ">Contract for services</h4>
                  <label className="">
                    * Please read and check/initial the following *
                  </label>
                </div>
                <div className="enrollment-col">
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">
                        Crackerdog uses Peanut Butter, cheese and Hotdogs for
                        treats and to administer medicine.
                      </label>
                      <Input
                        type="checkbox"
                        name="contract1"
                        value="✔️"
                        onChange={this.handleChange}
                        required
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="">
                        Crackerdog sometimes allows compatible dogs to play
                        together unsupervised.
                      </label>
                      <Input
                        type="checkbox"
                        name="contract2"
                        value="✔️"
                        onChange={this.handleChange}
                        required
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="">
                        Compatible dogs are sometimes allowed to board together.
                      </label>
                      <Input
                        type="checkbox"
                        name="contract3"
                        value="✔️"
                        onChange={this.handleChange}
                        required
                      />{" "}
                    </div>
                  </div>
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">
                        Crackerdog has the advantage of taking some dogs home
                        with us if we believe the dog would be more comfortable.
                      </label>
                      <Input
                        type="checkbox"
                        name="contract4"
                        value="✔️"
                        onChange={this.handleChange}
                        required
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">
                        All Dogs must be current on vaccinations: Rabies every
                        three years DHLLP annually Bordatella every year
                        (although vets require it every 6 mo)
                      </label>
                      <Input
                        type="checkbox"
                        name="vaccine"
                        value="✔️"
                        onChange={this.handleChange}
                        required
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">
                        All dogs must have a flat collar with a name tag on it
                        (current Rabies tag is also preferred).
                      </label>
                      <Input
                        type="checkbox"
                        name="collar"
                        value="✔️"
                        onChange={this.handleChange}
                        required
                      />{" "}
                    </div>
                  </div>
                  <div className="checkbox-cols">
                    <div className="checkbox-col">
                      <label className="">
                        In the event of an emergency, Crackerdog will attempt to
                        notify the owner but not hesitate obtain medical
                        treatment if the owner is not reached. Any medical costs
                        will be charged to the owner.
                      </label>
                      <Input
                        type="checkbox"
                        name="emergencyAuth"
                        value="✔️"
                        onChange={this.handleChange}
                        required
                      />{" "}
                    </div>
                    <div className="checkbox-col">
                      <label className="">
                        For the convenience of you and the staff, all drop off
                        and pick up are by appointment only during the following
                        hours: Mon-Fri 8-12 and 5-8 Sat 8-12 Sun 5-8. Please
                        call if you are going to be early or late. Anytime
                        appointments outside of these hours must be arranged
                        ahead of time.
                      </label>
                      <Input
                        type="checkbox"
                        name="pickupAuth"
                        value="✔️"
                        onChange={this.handleChange}
                        required
                      />{" "}
                    </div>

                    <div className="checkbox-col">
                      <label className="">
                        Crackerdog has a 48 hours cancellation policy for
                        regular stays and a nonrefundable nontransferable
                        deposit is required for holidays.
                      </label>
                      <Input
                        type="checkbox"
                        name="dropoffAuth"
                        value="✔️"
                        onChange={this.handleChange}
                        required
                      />{" "}
                    </div>

                    <div></div>
                  </div>

                  <div>
                    <div>
                      <label className="">
                        I hereby agree to the above as owner of{" "}
                        <Input
                          type="text"
                          name="contractAuthorization"
                          onChange={this.handleChange}
                          required
                        />
                      </label>

                      <Input
                        type="text"
                        placeholder="Owner's Signature"
                        name="ownersignature"
                        onChange={this.handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="">Date:</label>
                    <Input
                      type="date"
                      className="mt-2"
                      name="dateAuthorizationTwo"
                      onChange={this.handleChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <button className="enrollment-button">Submit</button>
            </Form>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Forms;
