import React, { Component } from "react";
import Navbar from "./Navbar";
import "./Emergency.css";
import logo from "./images/d50.JPG";
import Footer from "./Footer";


class Apply extends Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
    return (
      <div className="emergency">
        <Navbar />
        <div className="emergency-container">
        <div className="emergency-heading">
          <h4 className="emergency-header--main">Apply Now</h4>
        </div>
          <div className="emergency-cols">
            <div className="emergency-col">
            <div className="emergency-item">
              <div className="emergency-content">
                <img src={logo} alt="dog with big tennis ball" srcset=""/>
              </div>
            </div>
            </div>
            <div className="emergency-col">
            <div className="emergency-item">
              <div className="emergency-content">
                <h4>What we're looking for:</h4>
                <ul>
                  <li>Hard working</li>
                  <li>Positive attitude</li>
                  <li>Able to lift 40lbs</li>
                  <li>Passion for dogs</li>
                  <li>Customer service skills</li>
                </ul>
                <hr/>
                <h4>Responsibilities:</h4>
                <ul>
                <li>Walking all size dogs on uneven trails</li>
                <li>Rotating dogs in and out of yards</li>
                <li>Bathing dogs</li>
                <li>Feeding and giving medication</li>
                <li>Interacting with customers</li>
                <li>Cleaning yards and kennel</li>
                <li>Recording notes on each dog</li>
                <li>Hours are 7:15 am - 2 pm and 3:30 pm - 8 pm daily</li>
                <b><li>Pay: $12.00 - $13.00 per hour</li></b>
                </ul>
                <h4> Click and attach your resume!</h4>
                <a class="apply_btn" href="mailto:eppesaustin@gmail.com"><button>Apply Now</button></a>
              </div>
            </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default Apply;
