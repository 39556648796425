import React, { Component } from "react";
import "./About.css";
import Navbar from "./Navbar";
import Reveal from "react-reveal/Reveal";
import Footer from "./Footer";
import staffphoto from "./images/IMG_7666.jpeg";
import familyphoto from "./images/IMG_6605.jpeg";

class About extends Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
    return (
      <div className="about">
        <Navbar />
        <div className="about-container">
        <div className="about-heading">
            <h4>About Us</h4>
            </div>
          <div className="about-cols">
            <div className="about-col">
            
              <Reveal duration={2000} left>
                <div className="about-item">
                  <div className="about-img">
                    <img src={staffphoto} alt="crackerdog staff" className="about-photo" />
                  </div>
                  <div className="about-content">
                    <h4>Crackerdog Staff 2020</h4>
                    <div className="about-social">
                      <p className="about-social--heading">Follow us on social media for more info and pictures of your furry friends!</p>
                      <a href="#" class="fa fa-facebook"></a>
                      <a href="https://www.instagram.com/crackerdogcare/" class="fa fa-instagram"></a>
                    </div>
                  </div>
                </div>
              </Reveal>
            </div>
            <div className="about-col">
              <Reveal duration={2000} right>
                <div className="about-item">
                  <div className="about-img">
                    <img src={familyphoto} alt="crackerdog staff" className="about-photo" />
                  </div>
                  <div className="about-content">
                  <div className="about-text--wrap">
                  <h4 className="about-header">The Eppes Family</h4>
                    <p>
                      Posey is our little Crackerdog. She is a 25lb Schipperke mix
                      that we rescued from Town Lake Animal Shelter. Posey is "going
                      crackerdog" when she gets a burst of energy and runs around
                      really fast all over for no reason.
                  </p>
                    <p>
                      It is sort of a happy dance. She has always done this but we
                    never had a name for it until we heard the term in{" "}
                      <a href="https://www.thriftbooks.com/w/james-herriots-dog-stories_james-herriot/8954066/?mkwid=sPH40dz3R%7cdc&pcrid=70112865792&pkw=&pmt=&plc=&pgrid=21323651472&ptaid=aud-465071891222%3apla-293630284770&gclid=CjwKCAjw2cTmBRAVEiwA8YMgzYHcrOl9QNxe4KH_E6lLZhbHw4FFAnBErJljRZBUcDUUgbB6FQckyRoCTZsQAvD_BwE#isbn=0330326325&idiq=8076743">
                        <span> Dog Stories by James Harriot</span>
                      </a>
                      . Our logo is a picture of Posey going Crackerdog.
                    </p>
                    <p>
                      Holly and Chad Eppes decided to open a dog kennel when they
                      couldn't find one that met the individual needs of their dogs,
                      although a few came really close.
                    </p>
                    <p>
                      Holly is a true Dog Enthusiast. She began visiting
                <a href="http://www.austintexas.gov/department/aac">
                        {" "}
                        <span>Austin Animal Center</span>{" "}
                      </a>
                      during her frequent jogs and found out the horrors of an
                      overpopulated dog world. This spurred her involvement in
                <a href="https://www.austinpetsalive.org/">
                        {" "}
                        <span>Austin Pets Alive</span>{" "}
                      </a>
                      and in her own rescue efforts.
                    </p>
                    <p>
                      She studied different techniques for working with dogs and has
                      attended numerous seminars to educate herself and pass on to her
                      staff.
                    </p>
                  </div>
              
                  </div>
                </div>
              </Reveal>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default About;
