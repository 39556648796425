import React, { Component } from "react";
import axios from "axios";
import "./MedForm.css";
import Navbar from "./Navbar";
import { Input } from "reactstrap";
import Footer from "./Footer";
import medIcon from "./images/med-icon.png";


// Nodemailer submit component
class MedFormNew extends Component {
  constructor() {
    super();

    // State of current options
    this.state = {
      dogName: "",
      dogName2: "",
      dogName3: "",
      boardDates: "",
      boardDates2: "",
      boardDates3: "",
      boardEndDate: "",
      boardEndDate2: "",
      boardEndDate3: "",
      dosageMed: "",
      dosageMed2: "",
      dosageMed3: "",
      frequencyDosage: "",
      frequencyDosage2: "",
      frequencyDosage3: "",
      medComments: "",
      medComments2: "",
      medComments3: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
  }

  submitConfirmation() {
    window.scrollTo(0,0);
    document.querySelector(".alert").style.display = "block";
    setTimeout(function(){
      document.querySelector(".alert").style.display = "none";
    }, 3000);
  }
  
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    window.scrollTo(0,0);
  }

  async handleSubmit(e) {
    e.preventDefault();

    const {
      dogName,
      dogName2,
      dogName3,
      boardDates,
      boardDates2,
      boardDates3,
      boardEndDate,
      boardEndDate2,
      boardEndDate3,
      dosageMed,
      dosageMed2,
      dosageMed3,
      frequencyDosage,
      frequencyDosage2,
      frequencyDosage3,
      medComments,
      medComments2,
      medComments3,
    } = this.state;

    // eslint-disable-next-line
    const medform = 
    axios.post("/medform", {
      dogName,
      dogName2,
      dogName3,
      boardDates,
      boardDates2,
      boardDates3,
      boardEndDate,
      boardEndDate2,
      boardEndDate3,
      dosageMed,
      dosageMed2,
      dosageMed3,
      frequencyDosage,
      frequencyDosage2,
      frequencyDosage3,
      medComments,
      medComments2,
      medComments3,
    });
    this.submitConfirmation();
    document.getElementById("medform").reset();
  }

  render() {
    return (
      <div className="medform">
        <Navbar />
        <div className="medform-container">
        <center>
                  <div className="alert">
                    <p>🐶 Form Submission Successful! 🎉</p>
                  </div>
                </center>
          <div className="medform-heading">
            <div className="medform-glamour--cols">
            
              <div className="medform-glamour--col">
                <h4 className="medform-header">Medication Form</h4>
               
                <div className="medform-iconwrap">
                  <img
                    src={medIcon}
                    className="medform-icon"
                    alt="dog with emergency kit"
                  />
                </div>
                <div className="medform-content"></div>
              </div>
              <div className="medform-glamour--col">
                <div className="medform-download">
                  <p className="medform-subheader">
                    (One medication per section)
                  </p>
                  <p className="medform-download--links">
                    <span>
                      Don't want to fill out the emedform? Click the icon below
                      to print eform!
                    </span>
                  </p>
                  <p className="medform-download--links">
                    <span>Download Medication form docx.</span>
                  </p>
                  <a
                    href="https://drive.google.com/file/d/1_57HP3V5th-mpKSPnQCvLxkx-n-gKuoc/view?usp=sharing"
                    className="medform-download--icon"
                    alt="medforms"
                  >
                    <i className="fas fa-notes-medical"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="medform-cols">
            <div className="medform-col">
              <div className="medform-item">
                <div className="form-container">
                  <form
                    id="medform"
                    onSubmit={this.handleSubmit}
                    method="POST"
                    action="medform"
                  >
                    <div className="form-cols">
                      <div className="form-col">
                        <label className="medform-label" htmlFor="date">
                        <i className="fas fa-bone"></i> Dog Name:
                        </label>
                      </div>
                      <div className="form-col">
                        <input
                          value={this.state.dogName}
                          type="text"
                          name="dogName"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-cols">
                      <div className="form-col">
                        <label className="medform-label" htmlFor="date">
                        <i className="fas fa-calendar-times"></i> Boarding Start:
                        </label>
                      </div>
                      <div className="form-col">
                        <Input
                          type="date"
                          name="boardDates"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="form-cols">
                      <div className="form-col">
                        <label className="medform-label" htmlFor="date">
                        <i className="fas fa-calendar-times"></i> Boarding End:
                        </label>
                      </div>
                      <div className="form-col">
                        <Input
                          type="date"
                          name="boardEndDate"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="form-cols">
                      <div className="form-col">
                        <label className="medform-label">
                        <i className="fab fa-medrt"></i> Medication & Dosage:
                        </label>
                      </div>
                      <div className="form-col">
                        <textarea
                          type="textarea"
                          name="dosageMed"
                          onChange={this.handleChange}
                         
                        />
                      </div>
                    </div>

                    <div className="form-cols">
                      <div className="form-col">
                        <label className="medform-label">
                          Frequency/oral or on food:
                        </label>
                      </div>
                      <div className="form-col">
                        <textarea
                          type="textarea"
                          name="frequencyDosage"
                          onChange={this.handleChange}
                  
                        />
                      </div>
                    </div>

                    <div className="form-cols">
                      <div className="form-col">
                        <label className="medform-label" for="location">
                          Notes or Comments:
                        </label>
                      </div>
                      <div className="form-col">
                        <textarea
                          type="textarea"
                          name="medComments"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </form>
                  <form
                    id="medform"
                    onSubmit={this.handleSubmit}
                    method="POST"
                    action="medform"
                  >
                    <hr />
                    <p>
                      Dog #2 (If necessary)
                    </p>
                    <div className="form-cols">
                      <div className="form-col">
                        <label className="medform-label">
                        <i className="fas fa-bone"></i> Dog Name:
                        </label>
                      </div>
                      <div className="form-col">
                        <input
                          type="text"
                          name="dogName2"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-cols">
                      <div className="form-col">
                        <label className="medform-label">
                        <i className="fas fa-calendar-times"></i> Boarding Start:
                        </label>
                      </div>
                      <div className="form-col">
                        <Input
                          type="date"
                          name="boardDates2"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="form-cols">
                      <div className="form-col">
                        <label className="medform-label">
                        <i className="fas fa-calendar-times"></i> Boarding End:
                        </label>
                      </div>
                      <div className="form-col">
                        <Input
                          type="date"
                          name="boardEndDate2"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="form-cols">
                      <div className="form-col">
                        <label className="medform-label">
                          Medication & Dosage:
                        </label>
                      </div>
                      <div className="form-col">
                        <textarea
                          type="textarea"
                          name="dosageMed2"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="form-cols">
                      <div className="form-col">
                        <label className="medform-label">
                          Frequency/oral or on food:
                        </label>
                      </div>
                      <div className="form-col">
                        <textarea
                          type="textarea"
                          name="frequencyDosage2"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="form-cols">
                      <div className="form-col">
                        <label className="medform-label">
                          Notes or Comments:
                        </label>
                      </div>
                      <div className="form-col">
                        <textarea
                          type="textarea"
                          name="medComments2"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  </form>
                  <form
                    id="medform"
                    onSubmit={this.handleSubmit}
                    method="POST"
                    action="medform"
                  >
                    <hr />
                    <p>
                      Dog #3 (If necessary)
                    </p>
                    <div className="form-cols">
                      <div className="form-col">
                        <label className="medform-label">
                          Dog Name:
                        </label>
                      </div>
                      <div className="form-col">
                        <input
                          type="text"
                          name="dogName3"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="form-cols">
                      <div className="form-col">
                        <label className="medform-label" htmlFor="date">
                          Boarding Start:
                        </label>
                      </div>
                      <div className="form-col">
                        <Input
                          type="date"
                          name="boardDates3"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="form-cols">
                      <div className="form-col">
                        <label className="medform-label" htmlFor="date">
                          Boarding End:
                        </label>
                      </div>
                      <div className="form-col">
                        <Input
                          type="date"
                          name="boardEndDate3"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="form-cols">
                      <div className="form-col">
                        <label className="medform-label">
                          Medication & Dosage:
                        </label>
                      </div>
                      <div className="form-col">
                        <textarea
                          type="textarea"
                          name="dosageMed3"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="form-cols">
                      <div className="form-col">
                        <label className="medform-label">
                          Frequency/oral or on food:
                        </label>
                      </div>
                      <div className="form-col">
                        <textarea
                          type="textarea"
                          name="frequencyDosage3"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>

                    <div className="form-cols">
                      <div className="form-col">
                        <label className="medform-label">
                          Notes or Comments:
                        </label>
                      </div>
                      <div className="form-col">
                        <textarea
                          type="textarea"
                          name="medComments3"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <button id="medform" className="medform-submit">Submit</button>
                  </form>
                </div>
              </div>
            </div>
            <div className="medform-col">
              
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default MedFormNew;

