import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import Slide from 'react-reveal/Slide';
import footerlogo from "./images/tweb1.png";



class Footer extends Component {
  getYear() {
    return new Date().getFullYear();
  }
  render() {
    return (
        <div>
      <footer>
        <div className="footer-container">
          <div className="footer-cols">
            <div className="footer-col">
              <div className="footer-item">
                  <div className="footer-logo-wrapper">
                    <Link to="/login">
                    <Slide duration={2000} left cascade>
                      <img className="footer-logo--co" src={footerlogo} alt="" />
                      </Slide>
                    </Link>
                  </div>
                
              </div>
            </div>
            <Slide left cascade>
              <div className="footer-col">
                <div className="footer-item">
                  <Link to="/">
                    <div className="footer-logo">
                    <div className="footer-content">
                      <p>
                      Crackerdog Customized Canine Care. All rights reserved. &copy;<span>{this.getYear()}</span>
                      </p>
                    </div>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="footer-col">
                <div className="footer-item">
                  <ul className="footer-ul">
                    <li className="footer-links"><a href="#" className="fa fa-facebook"></a></li>
                    <li className="footer-links"><a href="https://www.instagram.com/crackerdogcare/" className="fa fa-instagram"></a></li>
                  </ul>
                </div>
              </div>
            </Slide>
          </div>
        </div>
      </footer>
        </div>
       
    );
  }
}

export default Footer;
