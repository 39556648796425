import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Navbar from "../layout/Navbar";
import "./Dashboard.css";
import Register from "../auth/Register";
import dogprofile from "../layout/images/dogprofile.png";
import Reveal from "react-reveal/Reveal";
import Zoom from 'react-reveal/Zoom';
import Footer from "../layout/Footer";
import CreateNotes from "../layout/CreateNotes";


class Dashboard extends Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };
  render() {
    const { user } = this.props.auth;
    return (
      <div className="dashboard">
        <Navbar />
        <div className="dashboard-container">

        <div className="dashboard-header">
          <div className="dashboard-header--cols">

          <div className="dashboard-header--col">
          <Reveal duration={3000}>
            <div className="dashboard-content">
            <h4 className="dashboard-header">Admin Controls</h4>
            </div>
          </Reveal>
          </div>
          </div>
        </div>

        <div className="dashboard-info">
        <div className="dashboard-info--cols">
          <div className="dashboard-info--col">
         
            <div className="dashboard-info-wrapper">
            <Zoom duration={3000}>
              <img className="dashboard-info-img" src={dogprofile} alt="dog-profile" />
            </Zoom>
            </div>
          
            <div className="dashboard-info--content">
              <Zoom duration={3000}> 
              <p className="dashboard-user"><b>Hey there,</b> {user.name.split(" ")[0]}!</p>
              <p> You are logged into your Crackerdog Admin Route!</p>
              <p>*Here you can post to the message board, update/create new passwords, and delete posts using mLab*</p>
              <button onClick={this.onLogoutClick} className="dashboard-button">Log out</button>
              </Zoom>
            </div>
          </div>

          <div className="dashboard-info--col">
            <div className="dashboard-content">
            <Zoom duration={3000}>
              <Register />
            </Zoom>
              <div className="dashboard-header--col">
                <Zoom duration={3000}>
                <CreateNotes/>
                <p>To delete previous post(s) go to mLab: username - tlewy password - Peppa$$88</p>
              <a href="https://mlab.com/databases/cd-users/collections/notes"><button className="dashboard-button">Delete Post(s)</button></a>
                </Zoom>
            </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <Footer/>
      </div>
    );
  }
}
Dashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(
  mapStateToProps,
  { logoutUser }
)(Dashboard);
