import React, { Component } from "react";
import Navbar from "./Navbar";
import "./Emergency.css";
import logo from "./images/d18.png";
import Footer from "./Footer";


class Emergency extends Component {
  componentDidMount() {
    window.scrollTo(0,0);
  }
  render() {
    return (
      <div className="emergency">
        <Navbar />
        <div className="emergency-container">
        <div className="emergency-heading">
          <h4 className="emergency-header--main"> <i class="fas fa-heartbeat"></i>  Emergency Information   <i class="far fa-plus-square"></i></h4>
        </div>
          <div className="emergency-cols">
            <div className="emergency-col">
            <div className="emergency-item">
              <div className="emergency-content">
                <img src={logo} alt="dog with big tennis ball" srcset=""/>
              </div>
            </div>
            </div>
            <div className="emergency-col">
            <div className="emergency-item">
              <div className="emergency-content">
                <h3 className="emergency-header">
                In case of a medical emergency the following steps will be taken
                to ensure the safety and well being of your pet:
              </h3>
              <hr/>
              <p>
                  Staff will take every step to contact the owner via information
                  on the enrollment form. If the owner’s veterinarian is available
                  we will take the dog to them.
                </p>
               
                <p>
                <i className="fas fa-paw" />{" "}
                  If the owner’s veterinary clinic is not available and incident
                  occurs during business hours then the dog will be taken by us to
                  the closest reputable veterinarian.
                </p>
              
              
                <p>
                <i className="fas fa-paw" />{" "}
                  If the incident occurs outside of business hours then we will
                  take the dog to Central Texas Veterinary Specialty & Emergency
                  Hospital.
                </p>
                <p>4434 Frontier Trail, Austin, TX 78745</p>
                <p>(512) 892-9038</p>
              
                <p>
                  <b><i className="fas fa-paw" />{" "} All veterinary expenses will be charged to the owner.</b>
                </p>
              </div>
            </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default Emergency;
