import React, { Component } from "react";
import axios from "axios";


export default class CreateNotes extends Component {
  constructor(props) {
    super(props);

    this.onChangeNotesDescription = this.onChangeNotesDescription.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      notes_description: "",
    };
  }

  onChangeNotesDescription(e) {
    this.setState({
      notes_description: e.target.value
    });
  }

  onSubmit(e) {
    e.preventDefault();

    console.log(`Form submitted:`);
    console.log(`notes Description: ${this.state.notes_description}`);

    const newNotes = {
      notes_description: this.state.notes_description,
    };

    axios
      .post("/notes/add", newNotes)
      .then(response => console.log(response.data));

    this.setState({
      notes_description: ""
    });
  }

  render() {
    return (
      <div className="post-message">
        <div className="post-container">
          <h4 className="post-heading">Create Post</h4>
          <form onSubmit={this.onSubmit}>
            <div className="note-form-group">
              <p className="post-heading">
                Add new post here:{" "}
              </p>
              <textarea
                type="text"
                className="post-textarea"
                value={this.state.notes_description}
                onChange={this.onChangeNotesDescription}
              />
              <div>
            </div>
            </div>
            <button  id="med-btn"type="submit"value="Post Message" className="dashboard-button">Post Message</button>
          </form>
        </div>
        
      </div>
      
    );
  }
}
